import React, { useState, useEffect, useRef } from 'react';
import projectsData from '../data/projects.json';
import CloseButton from '../components/CloseButton.js';
import Draggable from 'react-draggable';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Keyboard, Pagination, Navigation, EffectFade } from 'swiper/modules';

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const SwiperOverlay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [alignment, setAlignment] = useState({ alignItems: 'start', justifyContent: 'start' });
  const [shuffledProjects, setShuffledProjects] = useState([]);
  const overlayRef = useRef(null);
  const swiperRef = useRef(null); // Ref to control Swiper instance
  const videoRefs = useRef([]); // Refs to control video elements

  useEffect(() => {
    const alignments = [
      { alignItems: 'center', justifyContent: 'center' },
      { alignItems: 'center', justifyContent: 'start' },
      { alignItems: 'center', justifyContent: 'center' },
      { alignItems: 'center', justifyContent: 'end' },
      { alignItems: 'end', justifyContent: 'start' },
      { alignItems: 'center', justifyContent: 'center' },
      { alignItems: 'end', justifyContent: 'center' },
      { alignItems: 'center', justifyContent: 'center' },
      { alignItems: 'end', justifyContent: 'end' },
    ];
    const randomIndex = Math.floor(Math.random() * alignments.length);
    setAlignment(alignments[randomIndex]);
    setShuffledProjects(shuffleArray(projectsData));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [overlayRef]);

  useEffect(() => {
    // Attach `ended` event listeners to videos
    videoRefs.current.forEach((video) => {
      if (video) {
        video.addEventListener('ended', handleVideoEnded);
      }
    });

    return () => {
      // Clean up event listeners
      videoRefs.current.forEach((video) => {
        if (video) {
          video.removeEventListener('ended', handleVideoEnded);
        }
      });
    };
  }, [shuffledProjects]);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setIsVisible(false);
  };

  const handleVideoEnded = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <header style={{ display: isVisible ? 'flex' : 'none' }}>
      <div className='content-overlay' style={{ display: 'flex', alignItems: alignment.alignItems, justifyContent: alignment.justifyContent }}>
        <Draggable bounds="header" grid={[100, 100]}>
          <div>
            <div className='overlay' style={{ width: '100%', height: '100%' }} />
            <Swiper
              ref={swiperRef}
              onSlideChange={handleSlideChange}
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={false} // Remove autoplay from Swiper configuration
              speed={300} // Changed speed to 1000ms for better visualization
              navigation={true}
              keyboard={{ enabled: true }}
              autoHeight={true}
              modules={[Autoplay, Keyboard, Pagination, Navigation, EffectFade]}
              className="mySwiper"
            >
              <div className='sans swiper-title title'>
                <p className='p-small'>{shuffledProjects[currentIndex]?.title} {shuffledProjects[currentIndex]?.year}.<br/>{shuffledProjects[currentIndex]?.dimensions}</p>
              </div>
              <div className='handler'>
                <div className="swiper-close-button">
                  <button className="swiper-close-button-box" 
                  onClick={handleClick} onTouchEnd={handleClick} aria-label="Close"
                  style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0, margin: 0 }}>
                    <CloseButton />
                  </button>
                </div>
              </div>
              {shuffledProjects.map((project, i) => (
                <SwiperSlide key={i}>
                  {project.link && (
                    <img src={project.link} alt={`Slide ${i + 1}`} />
                  )}
                  {project.videolink && (
                    <video
                      ref={el => videoRefs.current[i] = el} // Assign ref to video
                      src={project.videolink}
                      className="swiper-video"
                      height='100%'
                      width='100%'
                      autoPlay
                      muted
                      loop={true} // Ensure video ends to trigger autoplay
                      playsInline
                      alt={`Video ${i + 1}`} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Draggable>
      </div>
    </header>
  );
};

export default SwiperOverlay;
